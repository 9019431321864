.App {
    display: flex;
    justify-content: center;
}

.App-body {
    flex: 1;
    margin: 24px;
    max-width: 1024px;
}

button {
    background-color: #eee;
    border: 1px solid #bbb;
    border-radius: 4px;
    padding: 0 4px;
}


.category-input {
    min-width: 250px;
}

.result-comment input {
    width: 100%;
    padding: 0 1rem 1rem;
    border: none;
    color: #778b91;
}
.result-comment input::placeholder {
    color: #d3edf4;
}

.snooze-control {
    display: flex;

}
