.date-select-input {
    min-width: 80px;
}
@media only screen and (max-width: 600px) {
    .date-select-input {
        min-width: 0;
        padding: 0 2px;
        font-size: 0.8rem;
    }
}
.react-datepicker-time__input {
    width: 100px !important;
}