/* Reset.less
 * Props to Eric Meyer (meyerweb.com) for his CSS reset file. We're using an adapted version here	that cuts out some of the reset HTML elements we will never need here (i.e., dfn, samp, etc).
 * ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,800');

html,
body {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
cite,
code,
del,
dfn,
em,
img,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dd,
dl,
dt,
li,
ol,
ul,
fieldset,
form,
label,
legend,
button,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: normal;
    font-style: normal;
    font-size: 100%;
    line-height: 1;
    font-family: inherit;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

ol,
ul {
    list-style: none;
}

q:before,
q:after,
blockquote:before,
blockquote:after {
    content: "";
}

html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

a:focus {
    outline: thin dotted;
}

a:hover,
a:active {
    outline: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
    display: block;
}

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
}

audio:not([controls]) {
    display: none;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

img {
    border: 0;
    -ms-interpolation-mode: bicubic;
}

button,
input,
select,
textarea {
    font-size: 100%;
    margin: 0;
    vertical-align: baseline;
    *vertical-align: middle;
}

button,
input {
    line-height: normal;
    *overflow: visible;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    cursor: pointer;
    -webkit-appearance: button;
}

input[type="search"] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

textarea {
    overflow: auto;
    vertical-align: top;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

@keyframes glow {
    0%,
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}

.force-wrap {
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    hyphens: auto;
}

.type-light {
    font-family: 'Nunito Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
}

.type-bold {
    font-family: 'Nunito Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 800;
}

.type-italic {
    font-family: 'Nunito Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-style: italic;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
button,
input,
textarea,
select {
    outline: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: 'Nunito Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    color: #333;
    font-size: 16px;
    background-color: #2f5c6e;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Nunito Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

h1 {
    font-size: 40px;
    line-height: 48px;
}

h2 {
    font-size: 28px;
    line-height: 32px;
}

h3 {
    font-size: 24px;
    line-height: 28px;
}

h4 {
    font-size: 20px;
    line-height: 24px;
}

h5 {
    font-size: 14px;
    line-height: 20px;
    color: #ccc;
    text-transform: uppercase;
}

h6 {
    color: #aaa;
}

p {
    font-size: 16px;
    line-height: 24px;
}

sub,
sup {
    font-size: 0.8em;
}

sub {
    bottom: -0.2em;
}

sup {
    top: -0.2em;
}

b {
    font-weight: bold;
}

em {
    font-style: italic;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="combobox"],
textarea {
    font-size: 14px;
    font-family: 'Nunito Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    padding: .75rem 0;
    line-height: 1.5rem !important;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    color: #333;
    outline: none;
}

input[type="text"] ::placeholder,
input[type="email"] ::placeholder,
input[type="password"] ::placeholder,
textarea ::placeholder {
    color: #778b91;
}

input[type="text"][disabled],
input[type="email"][disabled],
input[type="password"][disabled],
textarea[disabled] {
    opacity: .5;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset;
}

.checkbox {
    display: inline-block;
    height: 3rem;
    position: relative;
    vertical-align: middle;
    width: 44px;
}

.checkbox input[type="checkbox"] {
    font-size: 1em;
    visibility: hidden;
}

.checkbox input[type="checkbox"] + span:before {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    width: 0.85em;
    height: 0.85em;
    transform: translate3d(-50%, -50%, 0);
    background: transparent;
    box-shadow: #2cc5d2 0 0 0 1px inset;
    content: '';
    display: block;
}

.checkbox input[type="checkbox"]:checked + span:before {
    font-size: 16px;
    box-shadow: none;
    color: #2cc5d2;
    margin-top: -1px;
    font-family: 'percolate', sans-serif;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e65e";
}

.input-symbol {
    display: inline-block;
    position: relative;
}

.input-symbol.error [class^="icon-"],
.input-symbol.error [class*=" icon-"] {
    color: #ff4400;
}

.input-symbol [class^="icon-"],
.input-symbol [class*=" icon-"] {
    left: 1em;
}

.input-symbol input {
    padding-left: 3em;
}

.input-symbol input {
    width: 100%;
}

.input-symbol input:focus + [class^="icon-"],
.input-symbol input:focus + [class*=" icon-"] {
    color: #2cc5d2;
}

.input-symbol [class^="icon-"],
.input-symbol [class*=" icon-"] {
    transition: all 300ms ease-in;
    transform: translate3d(0, -50%, 0);
    background: transparent;
    color: #aaa;
    font-size: 1em;
    height: 1em;
    position: absolute;
    top: 50%;
    width: 1em;
}

@font-face {
    font-family: "percolate";
    src: url("/icon/percolate.eot?-5w3um4");
    src: url("/icon/percolate.eot?#iefix5w3um4") format("embedded-opentype"), url("/icon/percolate.woff?5w3um4") format("woff"), url("/icon/percolate.ttf?5w3um4") format("truetype"), url("/icon/percolate.svg?5w3um4") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
    font-family: "percolate", sans-serif;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-graphql:before {
    content: "\e90a";
}

.icon-redux:before {
    content: "\e908";
}

.icon-grid:before {
    content: "\e909";
}

.icon-redirect:before {
    content: "\e907";
}

.icon-grow:before {
    content: "\e903";
}

.icon-lightning:before {
    content: "\e904";
}

.icon-request-change:before {
    content: "\e905";
}

.icon-transfer:before {
    content: "\e906";
}

.icon-calendar:before {
    content: "\e902";
}

.icon-sidebar:before {
    content: "\e900";
}

.icon-tablet:before {
    content: "\e901";
}

.icon-atmosphere:before {
    content: "\e671";
}

.icon-browser:before {
    content: "\e672";
}

.icon-database:before {
    content: "\e673";
}

.icon-expand-alt:before {
    content: "\e674";
}

.icon-mobile:before {
    content: "\e675";
}

.icon-watch:before {
    content: "\e676";
}

.icon-home:before {
    content: "\e600";
}

.icon-user-alt:before {
    content: "\e601";
}

.icon-user:before {
    content: "\e602";
}

.icon-user-add:before {
    content: "\e603";
}

.icon-users:before {
    content: "\e604";
}

.icon-profile:before {
    content: "\e605";
}

.icon-bookmark:before {
    content: "\e606";
}

.icon-bookmark-hollow:before {
    content: "\e607";
}

.icon-star:before {
    content: "\e608";
}

.icon-star-hollow:before {
    content: "\e609";
}

.icon-circle:before {
    content: "\e60a";
}

.icon-circle-hollow:before {
    content: "\e60b";
}

.icon-heart:before {
    content: "\e60c";
}

.icon-heart-hollow:before {
    content: "\e60d";
}

.icon-face-happy:before {
    content: "\e60e";
}

.icon-face-sad:before {
    content: "\e60f";
}

.icon-face-neutral:before {
    content: "\e610";
}

.icon-lock:before {
    content: "\e611";
}

.icon-unlock:before {
    content: "\e612";
}

.icon-key:before {
    content: "\e613";
}

.icon-arrow-left-alt:before {
    content: "\e614";
}

.icon-arrow-right-alt:before {
    content: "\e615";
}

.icon-sync:before {
    content: "\e616";
}

.icon-reply:before {
    content: "\e617";
}

.icon-expand:before {
    content: "\e618";
}

.icon-arrow-left:before {
    content: "\e619";
}

.icon-arrow-up:before {
    content: "\e61a";
}

.icon-arrow-down:before {
    content: "\e61b";
}

.icon-arrow-right:before {
    content: "\e61c";
}

.icon-chevron-down:before {
    content: "\e61d";
}

.icon-back:before {
    content: "\e61e";
}

.icon-download:before {
    content: "\e61f";
}

.icon-upload:before {
    content: "\e620";
}

.icon-proceed:before {
    content: "\e621";
}

.icon-info:before {
    content: "\e622";
}

.icon-question:before {
    content: "\e623";
}

.icon-alert:before {
    content: "\e624";
}

.icon-edit:before {
    content: "\e625";
}

.icon-paintbrush:before {
    content: "\e626";
}

.icon-close:before {
    content: "\e627";
}

.icon-trash:before {
    content: "\e628";
}

.icon-cross:before {
    content: "\e629";
}

.icon-delete:before {
    content: "\e62a";
}

.icon-power:before {
    content: "\e62b";
}

.icon-add:before {
    content: "\e62c";
}

.icon-plus:before {
    content: "\e62d";
}

.icon-document:before {
    content: "\e62e";
}

.icon-graph-line:before {
    content: "\e62f";
}

.icon-doc-chart:before {
    content: "\e630";
}

.icon-doc-list:before {
    content: "\e631";
}

.icon-category:before {
    content: "\e632";
}

.icon-copy:before {
    content: "\e633";
}

.icon-book:before {
    content: "\e634";
}

.icon-certificate:before {
    content: "\e636";
}

.icon-print:before {
    content: "\e637";
}

.icon-list-unordered:before {
    content: "\e638";
}

.icon-graph-bar:before {
    content: "\e639";
}

.icon-menu:before {
    content: "\e63a";
}

.icon-filter:before {
    content: "\e63b";
}

.icon-ellipsis:before {
    content: "\e63c";
}

.icon-cog:before {
    content: "\e63d";
}

.icon-wrench:before {
    content: "\e63e";
}

.icon-nut:before {
    content: "\e63f";
}

.icon-camera:before {
    content: "\e640";
}

.icon-eye:before {
    content: "\e641";
}

.icon-photo:before {
    content: "\e642";
}

.icon-video:before {
    content: "\e643";
}

.icon-speaker:before {
    content: "\e644";
}

.icon-phone:before {
    content: "\e645";
}

.icon-flag:before {
    content: "\e646";
}

.icon-pin:before {
    content: "\e647";
}

.icon-compass:before {
    content: "\e648";
}

.icon-globe:before {
    content: "\e649";
}

.icon-location:before {
    content: "\e64a";
}

.icon-search:before {
    content: "\e64b";
}

.icon-timer:before {
    content: "\e64c";
}

.icon-time:before {
    content: "\e64d";
}

.icon-dashboard:before {
    content: "\e64e";
}

.icon-hourglass:before {
    content: "\e64f";
}

.icon-play:before {
    content: "\e650";
}

.icon-stop:before {
    content: "\e651";
}

.icon-email:before {
    content: "\e652";
}

.icon-comment:before {
    content: "\e653";
}

.icon-link:before {
    content: "\e654";
}

.icon-paperclip:before {
    content: "\e655";
}

.icon-box:before {
    content: "\e656";
}

.icon-structure:before {
    content: "\e657";
}

.icon-commit:before {
    content: "\e658";
}

.icon-cpu:before {
    content: "\e659";
}

.icon-memory:before {
    content: "\e65a";
}

.icon-outbox:before {
    content: "\e65b";
}

.icon-share:before {
    content: "\e65c";
}

.icon-button:before {
    content: "\e65d";
}

.icon-check:before {
    content: "\e65e";
}

.icon-form:before {
    content: "\e65f";
}

.icon-admin:before {
    content: "\e660";
}

.icon-paragraph:before {
    content: "\e661";
}

.icon-bell:before {
    content: "\e662";
}

.icon-rss:before {
    content: "\e663";
}

.icon-basket:before {
    content: "\e664";
}

.icon-credit:before {
    content: "\e665";
}

.icon-support:before {
    content: "\e666";
}

.icon-shield:before {
    content: "\e667";
}

.icon-beaker:before {
    content: "\e668";
}

.icon-google:before {
    content: "\e669";
}

.icon-gdrive:before {
    content: "\e66a";
}

.icon-youtube:before {
    content: "\e66b";
}

.icon-facebook:before {
    content: "\e66c";
}

.icon-thumbs-up:before {
    content: "\e66d";
}

.icon-twitter:before {
    content: "\e66e";
}

.icon-github:before {
    content: "\e66f";
}

.icon-meteor:before {
    content: "\e670";
}

a {
    transition: all 200ms ease-in;
    color: #5db9ff;
    cursor: pointer;
    text-decoration: none;
}

a:hover {
    color: #239da8;
}

a:active {
    color: #555;
}

a:focus {
    outline: none;
}

.list-heading {
    letter-spacing: .3em;
    text-indent: .3em;
    text-transform: uppercase;
    font-family: 'Nunito Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 800;
    font-size: 11px;
    padding-left: 15px;
    line-height: 40px;
    background: #f8f8f8;
    color: #aaa;
}

.list-heading .icon-sync {
    opacity: 1;
    animation: spin 2s infinite linear;
    display: inline-block;
    margin-right: 4px;
}

.list-item {
    font-size: 14px;
    line-height: 20px;
    display: flex;
    flex-wrap: wrap;
    min-height: 3rem;
    width: 100%;
    background: white;
    transition: all ease-out 150ms;
}

.list-item .title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
}

.list-item .time {
    width: 5.5rem;
    padding: 0.75rem 1rem 0 0 ;
    color: #aaaaaa;
}

.list-item .computed {
    color: #778b91;
    padding: 0 0.5rem;
    text-align: right;
}

.list-item input[type="text"] {
    background: transparent;
    width: 100%;
}

.list-item input[type="text"]:focus {
    cursor: text;
}

.list-item .actions {
    transition: all 200ms ease-in;
    padding-right: 20px;
}

.list-item .actions a {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    color: #eee;
}

.list-item .actions a:hover {
    color: #2cc5d2;
}

.list-item .actions a:active {
    color: #555;
}

.list-item .actions [class^="icon-"] {
    font-size: 16px;
    line-height: 3rem;
    text-align: center;
}

.list-item.TASK_PINNED .icon-star {
    color: #2cc5d2;
}

.list-item.TASK_ARCHIVED input[type="text"] {
    color: #aaa;
}

.list-item:hover {
    background-image: linear-gradient(to bottom, #e5f9f7 0%, #f0fffd 100%);
}

.list-item:hover .checkbox {
    cursor: pointer;
}

.list-item + .list-item {
    border-top: 1px solid #f0f9fb;
}

.list-item.checked input[type="text"] {
    color: #ccc;
    text-decoration: line-through;
}

.list-item.checked .delete-item {
    display: inline-block;
}

.loading-item {
    height: 3rem;
    width: 100%;
    background: white;
    display: flex;
    align-items: center;
    line-height: 1rem;
    padding-left: 16px;
}

.loading-item .glow-checkbox,
.loading-item .glow-text span {
    animation: glow 1.5s ease-in-out infinite;
    background: #eee;
    color: transparent;
    cursor: progress;
    display: inline-block;
}

.loading-item .glow-checkbox {
    margin-right: 16px;
    width: 12px;
    height: 12px;
}

.loading-item + .loading-item {
    border-top: 1px solid #f0f9fb;
}

.list-items {
    position: relative;
    background: white;
    min-height: 288px;
}

.list-items .select-placeholder {
    border: none;
    width: 48px;
}

.wrapper-message {
    position: absolute;
    top: 400px;
    right: 0;
    bottom: auto;
    left: 0;
    width: auto;
    height: auto;
    transform: translate3d(0, -50%, 0);
    text-align: center;
}

.wrapper-message [class^="icon-"],
.wrapper-message [class*=" icon-"] {
    font-size: 48px;
    line-height: 56px;
    color: #2cc5d2;
    display: block;
}

.wrapper-message .title-message {
    font-size: 16px;
    line-height: 24px;
    font-family: 'Nunito Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 800;
    color: #555;
}

.wrapper-message .subtitle-message {
    font-size: 14px;
    line-height: 20px;
    color: #666;
}

.page.lists-show {
    min-height: 100vh;
    background: white;
}

.list-item {
    padding: 0 1.5rem;
}

.list-item-text {
    padding: 0.75rem 0;
}

.result-list-item {
    display: flex;
    width: 100%;
    align-items: center;
}

.result-list-group-label {
    padding-left: 1rem;
    font-weight: 600;
    color: #000000;
}

.result-list-link {
    flex: 1
}

.time-delta {
    width: 5rem;
    text-align: right;
}

.time-delta-ahead {
    color: #43b823;
}
.time-delta-behind {
    color: #b52621;
}
.time-delta-okay {
    color: #666;
}

.result-list-item .result-indicator-wrapper {
    display: flex;
    flex: 1;
}
.result-list-item .result-indicator-wrapper-inner {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.result-indicator {
    color: #eeeeee;
    text-align: right;
    background-color: #778b91;
    flex: 1;
    display: flex;
    margin-left: 0.25rem;
}

.indicator-footer {
    flex: 1;
    display: flex;
    color: #778b91;
    margin-left: 0.25rem;
}

.step-info {
    flex: 1;
    text-align: right;
    margin-left: 0.25rem;
    min-width: 150px;
}

.result-list-item .indicator-details {
    display: none;
}

.indicator-details {
    float: left
}
.result-info {
    padding: 1.5rem;
    border-bottom: 1px solid #1c3f53;
}

.mobile-only {
    display: none;
}

@media only screen and (max-width: 600px) {
    .result-list-item .result-indicator {
        display: none;
    }
    .target-info {
        display: none;
    }

    .no-mobile {
        display: none;
    }
    .mobile-only {
        display: block;
    }

    .App-body {
        margin: 8px !important;
    }

    nav {
        padding: 1rem !important;
    }

    .time-delta {
        flex: 1;
    }
    .result-list-link {
        flex: 2;
    }

    .list-item .time {
        width: 4.5rem;
    }

    .list-item {
        padding: 0 1rem;
    }
    .result-info {
        padding: 1rem;
    }

    .step-experiments-row {
        flex-direction: column;
    }
}

.target-info {
    margin: 0.5rem 0;
}


.result-indicator-text {
    padding: 0 0.25rem;
}

.result-progress-chunk {
    direction: rtl;
}

.result-progress-expected-good {
    background-color: #2c69b8;
}
.result-progress-actual-good {
    background-color: #43b823;
}
.result-progress-actual-no-goal {
    background-color: #43b823;
    direction: ltr;
}
.result-progress-expected-bad {
    background-color: #b52621;
}
.result-progress-projected-good {
    background-color: #cfaf0b;
}
.result-progress-projected-close {
    background-color: #cfaf0b;
}
.result-progress-expected-close {
    background-color: #b56121;
}
.result-progress-projected-bad {
    background-color: #b56121;
}
.result-progress-actual-bad {
    background-color: #2c69b8;
}
.result-comparison-good {
    color: #43b823;
}
.result-comparison-close {
    color: #806b06;
}
.result-comparison-bad {
    color: #b52621;
}
.page.lists-show nav {
    background: #d3edf4;
    padding: 1.5rem 1.25rem;
    text-align: center;
}

@media screen and (min-width: 40em) {
    .page.lists-show nav {
        text-align: left;
    }
}

.page.lists-show nav .title-page {
    font-size: 20px;
    line-height: 2rem;
    cursor: pointer;
    white-space: nowrap;
}

.nav-tabs {
    text-align: right;

}
.nav-tabs a {
    display: inline-block;
    padding-left: 0.5rem
}


.page.lists-show nav .title-page .title-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Nunito Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 800;
    color: #1c3f53;
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
}

.result-list-view-options {
    padding: 0.5rem 1rem;
    line-height: 1.8;
}

.result-list-view-options .link-or-button {
    margin-right: 0.5rem;
}

.task-creator {
    background-color: red;
}

.category-info {
    padding: 1.5rem;
    border-bottom: 1px solid #1c3f53;
}
.category-crumbs {
    font-size: 1.5rem;
    font-weight: 200;
}

.result-info .result-indicator-wrapper {
    margin-bottom: 1rem
}

.result-info h2 {
    margin-bottom: 1rem;
}

.delete-button {
    color: #ac1736;
    float: right;
}

.result-target-label {
    display: inline-block;
    width: 3rem;
}

.date-picker-wrapper {
    display: inline-block;
}

.target-units-input {
    width: 5rem;
    margin: 0 0.25rem;
    text-align: center;
}

.step-input {
    width: 3rem;
    text-align: center;
    margin: 0.25rem 0.5rem 0.5rem;
}

.target-rate-input {
    width: 3rem;
    margin: 0 0.25rem;
    text-align: center;
}

.target-value-input {
    text-align: center;
    width: 50px;
}

.result-date-input {
    margin: 0 1rem;
}

.result-graph {
    margin-top: 1rem;
}

.magic-input {

}
.magic-input-invalid {
    color: red;
}
.magic-input-editing {
    color: green;
}
.magic-input-editing_invalid {
    color: orange;
}

.button-confirming {
    color: white;
    background-color: darkred;
    padding: 0 0.5rem;
}

.button-confirming-good {
    color: white;
    background-color: darkgreen;
    padding: 0 0.5rem;
}
.mark-complete-prompt {
    color: white;
    background-color: darkgreen;
    margin: 0.5rem 0;
    padding: 0 0.5rem;
}

.mobile-rate-indicator {
    text-align: right;
    color: #666;
    padding-top: 0.1rem;
}

.result-list-empty {
    text-align: center;
}

.step-experiments-row {
    display: flex;
}
.step-experiments-cell{
    flex: 1;
    padding-bottom: 1rem;
}

.rate-rollup-entry {
    width: 3rem;
    text-align: center;
}
.no-top-margin {
    margin-top: 0
}